import Script from "next/script";

const HubSpotChatLoader: React.FC = () => {
    return (
        <Script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js-eu1.hs-scripts.com/26196886.js"
        />
    );
};

export default HubSpotChatLoader;
